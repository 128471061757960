
import { HubConnectionBuilder, LogLevel, HttpTransportType, JsonHubProtocol } from '@microsoft/signalr';
const connection = new HubConnectionBuilder()
    .withUrl("/BasketHub",  {
        skipNegotiation: false,
        HttpTransportType:  HttpTransportType.WebSockets
    })
    // .withHubProtocol(new MessagePackHubProtocol())
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Debug)
    .withAutomaticReconnect()
    .build();

//Disable the send button until connection is established.

async function start() {
    try {
        await connection.start();
        connection.invoke("SendBasketCount").then(function (res) {
            //console.log('RES', res);
        });
    } catch (err) {
        console.log(err);
        setTimeout(start, 5000);
    }
};


connection.on("ReceiveBasketCount", function (basketCount) {
    document.getElementById("basketCount").setAttribute("data-count", basketCount);
});

connection.onreconnected(connectionId => {
    console.log('reconnecting signalr');
});

connection.onclose(async () => {
    await start();
});



start();

export default connection;


